/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SmsEnrollmentResponse } from '../models/SmsEnrollmentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SmsEnrollmentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve SMS enrollment status for a specific phone number.
     * Check the enrollment status of a phone number for SMS messaging.
     * @returns SmsEnrollmentResponse OK
     * @throws ApiError
     */
    public getSmsEnrollmentStatus({
        phoneNumber,
    }: {
        /**
         * Phone number to check the enrollment status for.
         */
        phoneNumber: string,
    }): CancelablePromise<SmsEnrollmentResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sms/enrollment-status',
            query: {
                'phone_number': phoneNumber,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Update or create SMS enrollment status for a specific phone number.
     * Updates the enrollment status for SMS messaging or creates a new one if it does not exist.
     * @returns SmsEnrollmentResponse OK
     * @throws ApiError
     */
    public updateSmsEnrollmentStatus({
        requestBody,
    }: {
        /**
         * Request payload for updating SMS enrollment status
         */
        requestBody: {
            phone_number: string;
            consent: 'verbal' | 'written' | 'owner';
        },
    }): CancelablePromise<SmsEnrollmentResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sms/enrollment-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Exports SMS enrollment status data for the authenticated user's organization.
     * Export the SMS shortcode preferences data as a CSV file.
     * @returns string OK
     * @throws ApiError
     */
    public exportSmsEnrollmentData(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sms/enrollment-status/export',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
