export default {
    bulkSms: {
        title: `New Text`,
        to: `To:`,
        send: `Send Now | Send to 1 person | Send to %{count} people`,
        search: `Search for contacts...`,
        placeholder: `Start typing your text...`,
        linkPhoto: `Link to Photo or File`,
        shortenUrl: `Shorten a URL`,
        charactersRemaining: `%{count} Characters remaining`,
        change: `change`,
        options: {
            sendNow: `Send Now`,
            sendCustom: `Send on custom date and time`,
            sendTomorrow3: `Send tomorrow at 3pm`,
            sendTomorrow9: `Send tomorrow at 9am`,
            sendIn6: `Send in 6 hours`,
            am: `AM`,
            pm: `PM`
        },
        instructions: `When linking to a photo or file, Breeze uses a URL shortener to give you more space to type your message`,
        textRemain: `%{count} Texts Remain this Month`,
        searching: `Searching...`,
        searchContacts: `Search for contacts...`,
        noNumber: `No mobile number`,
        alreadyRecipient: `Already a recipient`,
        loadingContacts: `Loading contacts...`,
        success: `Text sent successfully!`,
        date: `Date`,
        time: `Time`,
        validation: {
            mustHaveAtleast: `Must have atleast 1 recipient.`,
            recipientsRequired: `Recipients are required.`,
            messageRequired: `Message is required.`
        }
    },
    addFamilyMember: {
        title: `Add Family Member`,
        options: {
            newPerson: {
                title: `New Person`,
                description: `Add a new person to the database as a member of this family.`
            },
            existingPerson: {
                title: `Existing Person`,
                description: `Select a person already in the database to associate as a family member.`
            }
        },
        cancel: `Cancel`
    },
    addFamilyMemberSearch: {
        title: `Add Family Member`,
        personNotFound: 'Person Not Found',
        selectDifferent: 'Select a different person',
        addFamilyMember: 'Add Family Member',
        cancel: `Cancel`
    },
    displayFields: {
        title: `Display Fields`,
        instructions: `Select the profile fields you'd like to include when adding new people. These preferences will be saved for your user. In order to add additional field options, you will need to add a custom field in the Profile Fields section.`
    },
    bulkUpdateModal: {
        title: `Bulk Update People`,
        personConfirmButton: `Update 1 Person`,
        peopleConfirmButton: `Update %{peopleCount} People`,
        confirmText: `This action will update all selected profiles with the information provided below.
        Applies to: %{peopleCount} person selected from your list`,
        successUpdate: `Updated people successfully`,
        errorUpdate: `Failed to update people.`
    },
    bulkArchiveModal: {
        title: `Bulk Archive People`,
        personConfirmButton: `Archive 1 Person`,
        peopleConfirmButton: `Archive %{peopleCount} People`,
        confirmText: `Archiving people will remove them from the main People page, general search results, and family member profiles. All of their information will be maintained and accessible. This action can be undone.
        Applies to: %{peopleCount} person selected from your list`,
        successArchive: `Archived people successfully`,
        errorArchive: `Failed to archive people.`
    },
    archiveModal: {
        title: `Archive Person`,
        titleUnarchive: `Unarchive Person`,
        confirmButton: `Archive`,
        confirmButtonUnarchive: `Unarchive`,
        learnMore: `Learn More`,
        cancel: `Cancel`,
        confirmText: `Do you really want to archive this person?`,
        confirmTextUnarchive: `Do you really want to unarchive this person?`,
        successArchive: `Archived person successfully`,
        successUnarchive: `Unarchived person successfully`,
        errorArchive: `Failed to archive person.`,
        errorUnarchive: `Failed to unarchive person.`
    },
    bulkUnarchiveModal: {
        title: `Bulk Unarchive People`,
        personConfirmButton: `Unarchive 1 Person`,
        peopleConfirmButton: `Unarchive %{peopleCount} People`,
        confirmText: `Unarchiving people will add them back into the main People page, general search results, family member profiles, and the Tags they were in before being archived.
        Applies to: %{peopleCount} person selected from your list`,
        successArchive: `Unarchived people successfully`,
        errorArchive: `Failed to unarchive people.`
    },
    bulkDeleteModal: {
        onConfirmError: `An error occurred while deleting people.`,
        onConfirmSuccess: `Person deleted successfully.`,
        title: `Bulk Delete People`,
        warning: `Deleting people will remove them and all of their information from Breeze. This action can be undone for 30 days.`
    },
    person: {
        noInfo: `No information stored.`,
        noContactInfoAvailable: `No contact information available.`,
        familyRole: {
            unassigned: `Unassigned`,
            child: `Child`,
            adult: `Adult`,
            headOfHousehold: `Head of Household`,
            spouse: `Spouse`
        },
        clickToEditSection: `click to edit this section`,
        save: `Save`,
        cancel: `Cancel`,
        phoneInput: {
            showMore: `Show More Options`,
            showFewer: `Show Fewer Options`,
            doNotText: `Do Not Text`,
            makePrivate: `Make Private`,
            private: `Private`
        },
        addressInput: {
            showMore: `Show More Options`,
            showFewer: `Show Fewer Options`,
            applyAddressToAll: `Apply address changes to all family members`,
            makePrivate: `Make Private`,
            private: `Private`
        },
        emailInput: {
            showMore: `Show More Options`,
            showFewer: `Show Fewer Options`,
            doNotEmail: `Do Not Email`,
            makePrivate: `Make Private`,
            private: `Private`
        },
        gradeInput: {
            setByGraduationYear: `Set by Graduation Year`,
            setByGrade: `Set by Grade`,
            grade: `Grade`,
            graduationYear: `Graduation Year`
        }
    }
}
